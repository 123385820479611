







































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard
} from "@/configuration";
import ProjectStatus from "@/components/dashboard/ProjectStatus.vue";
import DatatableFilterPlugin from "@/filters";
import TableResponsive from "@/components/table/TableResponsive.vue";
// @ts-ignore
import JsonExcel from "vue-json-excel";
import moment from "moment";
import API from "@/api";

Vue.component("downloadExcel", JsonExcel);
Vue.use(DatatableFilterPlugin);
@Component({
  metaInfo: {
    title: "Estado pagos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    filteredDatatable() {
      return this.$data.newPayments.filter((i: any) =>
        Object.keys(this.$data.filters).every(
          f =>
            this.$data.filters[f].length < 0 ||
            this.$data.filters[f].includes(i[f])
        )
      );
    },
    filtered2() {
      return this.$data.newPayments.filter((i: any) => {
        // Props 
        let propsObjTableAll = {
          style: `text-align: center;
              font-size: 0.7rem;
              font-weight: 500;
              color: #8b8b8b`
        }
        // Props 
        let propsObjTableEstado = {
          style: `display: flex;
              justify-content: center;`
        }
        // Formato tabla
        let objTable= {
          id : {
							value: i.proyecto.id,
							label: 'ID',
							props: propsObjTableAll
					},
          nombre:{
            value: i.proyecto.nombre,
            label: 'Proyecto',
            props: propsObjTableAll
          },
          inversion:{
            value: i.proyecto.proyecto_moneda.moneda.simbolo + (i.monto + i.saldo_pendiente),
            label: 'Inversión',
            props: propsObjTableAll
          },
          montoFecha:{
            // @ts-ignore
            value: i.proyecto.proyecto_moneda.moneda.simbolo + i.inversionista_flujos.map(flujo => flujo.flujo_real).reduce((last, current) => last + current, ""),
            label: 'Monto recibido a la fecha',
            props: propsObjTableAll
          },
          montoRecibir:{
            // @ts-ignore
            value: i.proyecto.proyecto_moneda.moneda.simbolo + i.inversionista_flujos.map(flujo => flujo.flujo_inv).reduce((last, current) => last + current, ""),
            label: 'Monto a recibir',
            props: propsObjTableAll
          },
          cuotaPagar:{
            // @ts-ignore
            value: moment(i.inversionista_flujos.filter(flujo => flujo.pagado == 0).length > 0 ? i.inversionista_flujos.filter(flujo => flujo.pagado == 0)[0].vencimiento : '-').format("DD-MM-YYYY"),
            label: 'Cuota por pagar',
            props: propsObjTableAll
          },
          proximaCuota:{
            // @ts-ignore
            value: moment(i.inversionista_flujos.filter(flujo => flujo.pagado == 0).length > 0 ?  i.inversionista_flujos.filter(flujo => flujo.pagado == 0)[0].vencimiento : '-').format("DD-MM-YYYY") ,            
            label: 'Proxima cuota',
            props: propsObjTableAll
          },
          estado: {
            value: '',
            label: 'Estado',
            props: propsObjTableEstado,
            component: {
              name: 'ProjectStatus',
              props: {
                'project-status' : i[2]
              }
            }
          },
          acciones: {
            value: '',
            label: 'Acciones',
            props: propsObjTableEstado,
            buttons: [
              {
                funcion: 'showFeesChild',
                icoFont: 'visibility',
                data: i.id
              },
              {
                funcion: 'showCommentsChild',
                icoFont: 'chat',
                data: i.id
              }								
            ]
          }
        }
        i.datosEnTabla = objTable;
        return (
          this.$data.name.includes(i.proyecto.nombre) ||
          this.$data.id.includes(i.proyecto.id)
        );
      });
    },
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      penpayment:'',
      RedCapital,
      SelectedCountry,
      filters: {
        id: null,
        name: null,
        state: null,
      },
      id: "",
      name: "",
      state: "",
      canShow: false,
      showId: false,
      payments: null,
      newPayments: null,
      states: null,
      dialog: false,
      viewFees: false,
      feesHeaders: [
        {
          text: "Numero cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Vencimiento Cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Monto Cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Monto pagado a la Fecha",
          align: "center",
          sortable: false
        },
        {
          text: "Estado cuota",
          align: "center",
          sortable: false
        }
      ],
      headers: [
        {
          text: "ID",
          align: "center",
          value: "proyecto.id"
        },
        {
          text: "Proyecto",
          align: "center",
          value: "proyecto.nombre"
        },
        {
          text: "Inversión",
          align: "center",
          value: "monto"
        },
        {
          text: "Monto recibido a la fecha",
          align: "center",
          value: "proyecto.nombre"
        },
        {
          text: "Monto a recibir",
          align: "center",
          value: "proyecto.nombre"
        },
        {
          text: "Cuota por pagar",
          align: "center",
          sortable: false
        },
        {
          text: "Próxima cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Estado",
          align: "center",
          sortable: false
        },
        {
          text: "Detalle de Cuotas",
          align: "center",
          sortable: false
        },
        {
          text: "Comentario",
          align: "center",
          sortable: false
        }
      ],
      tablaData: {
        headers: [
          {
            text: "ID",
            align: "center",
            value: "proyecto.id"
          },
          {
            text: "Proyecto",
            align: "center",
            value: "proyecto.nombre"
          },
          {
            text: "Inversión",
            align: "center",
            value: "monto"
          },
          {
            text: "Monto recibido a la fecha",
            align: "center",
            value: "proyecto.nombre"
          },
          {
            text: "Monto a recibir",
            align: "center",
            value: "proyecto.nombre"
          },
          {
            text: "Cuota por pagar",
            align: "center",
            sortable: false
          },
          {
            text: "Próxima cuota",
            align: "center",
            sortable: false
          },
          {
            text: "Estado",
            align: "center",
            sortable: false
          },
          {
            text: "Acciones",
            align: "center",
            sortable: false
          },
        ],
					msjEmpty: 'No existen registros en tu cuenta',
					rowsPerPage: {
						txt: 'Registro por página',
						itm: [10, 25, 50, { text: 'Todos', value: -1 }]
					},
					pagination: {
						sortBy: "created_at",
						descending: true,
						page: 1
					},
      }
    };
  },
  async beforeMount() {
    API.getMyInvestments().then((payments: any) => {
      this.$data.payments = payments;
      this.$data.penpayment = payments;
      this.$data.penpayment = payments.map((i: any) => {
            return {
              Proyecto_id: i.proyecto_id,
              Proyecto: i.proyecto.nombre,
              inversion: i.monto + i.saldo_pendiente,
              // @ts-ignore
              recibido_a_la_fecha: i.inversionista_flujos.map(flujo => flujo.flujo_real).reduce((last, current) => last + current, 0),
              // @ts-ignore
              monto_a_recibir: i.inversionista_flujos.map(flujo => flujo.flujo_inv).reduce((last, current) => last + current, 0),
              // @ts-ignore
              cuota_por_pagar: i.inversionista_flujos.filter(flujo => flujo.pagado == 0).length > 0 ? i.inversionista_flujos.filter(flujo => flujo.pagado == 0)[0].vencimiento  : '-' ,
              // @ts-ignore
              Prox_cuota: i.inversionista_flujos.filter(flujo => flujo.pagado == 0).length > 0 ?  i.inversionista_flujos.filter(flujo => flujo.pagado == 0)[0].vencimiento : '-' ,
              estado: i.proyecto.estado_proyecto.valor
              
            };
          })
      this.$data.payments.forEach((el:any)=>{
        el.monto = parseFloat(el.monto)
        el.saldo_pendiente = parseFloat(el.saldo_pendiente)
        el.monto_a_invertir = parseFloat(el.monto_a_invertir)
      })
      API.getMyStates().then((states: any) => {
        this.$data.states = states;
        let arr = this.$data.payments.map((item: any, i: any) =>
          Object.assign({}, item, states[i])
        );
        this.$data.newPayments = arr;
        this.$data.filters.id = this.$data.newPayments
          .map((investment: any) => investment.proyecto.id)
          .filter((val: any) => val !== null);
        this.$data.filters.name = this.$data.newPayments
          .map((investment: any) => investment.proyecto.nombre)
          .filter((val: any) => val !== null);
        this.$data.canShow = true;
      });
      // this.$data.payments = (await API.getMyInvestments())
      // this.$data.canShow = true
    });
  },
  methods: {
    getInvestById(id: number) {
      if (this.$data.payments != null) {
        return this.$data.payments.find((el: any) => el.id == id);
      } else {
        return null;
      }
    },
    showFees(id: number) {
      this.$data.showId = id;
      this.$data.viewFees = true;
    },
    showComments(id: number) {
      this.$data.showId = id;
      this.$data.dialog = true;
    },
    fees() {
      if (this.$data.payments == null) {
        return [];
      }
      return this.$data.payments.find((el: any) => el.id == this.$data.showId)
        .inversionista_flujos; /* .filter((i: any) => i.flujo_inv > 0) */
    },
    resetFilters() {
      this.$data.id = "";
      this.$data.name = "";
      this.$data.state = ""
    }
  },
  components: {
    ProjectStatus,
    TableResponsive,
  }
})
export default class PaymentStatus extends Vue {}
